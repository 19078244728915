import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="fb-root" />
        
        
        <div className="addbg2">
          <div className="wrapper">
            <header
              style={{
                height: "120px"
              }}
            >
              <div
                style={{
                  height: "67px"
                }}
              >
                <h1 className="logo">
                  <a href="/" />
                </h1>
                <nav>
                  <a href="/site/produkty/" className="link">
                    Produkty
                  </a>
                  <span>| </span>
                  <a href="/site/o-neutrogenie/kim-jestesmy/" className="link">
                    O Neutrogenie
                  </a>
                  <span>| </span>
                  <a href="/site/bad-piekna/" className="link">
                    Bądź piękna
                  </a>
                  <span>| </span>
                  <a href="/site/see-whats-possible/" className="link">
                    See What's Possible
                  </a>
                  <span>| </span>
                  <a href="/site/skora-z-niedoskonalosciami/" className="link">
                    Skóra z niedoskonałościami
                  </a>
                  <span>| </span>
                </nav>
              </div>
              <div
                style={{
                  "padding-left": "185px",
                  height: "53px"
                }}
              >
                <nav
                  className="second"
                  style={{
                    float: "none"
                  }}
                >
                  <a href="/site/hydroboost/" className="link">
                    Hydro Boost
                  </a>
                  <span>| </span>
                  <a href="/site/skin-detox/" className="link">
                    Skin Detox
                  </a>
                  <span>| </span>
                  <a href="/site/cellular-boost/" className="link">
                    Cellular Boost
                  </a>
                  <span />
                </nav>
              </div>
            </header>
            <div className="content">
              <div className="breadcrumb">
                <a href="/">NEUTROGENA®</a>
                <span>&gt;</span>
                <a href="/site/produkty/">Opis</a>
                <span>&gt;</span>
                <a href="/site/product/60/" className="blue">
                  {" "}
                  <p>
                    NEUTROGENA<sup>®&nbsp;</sup>VISIBLY CLEAR<sup>®&nbsp;</sup>
                    Spot proofing ™- Nawilżający krem do twarzy
                  </p>{" "}
                </a>
              </div>
              <div className="singleproduct">
                <div className="prodimgcont">
                  <div className="bot">
                    <img
                      src="/site/assets/Uploads/NOWY-VisiblyClear-PG-Moisturiser-Pudelko-copy.jpg"
                      alt="NOWY VisiblyClear PG Moisturiser Pudelko copy"
                    />
                  </div>
                </div>
                <div className="info">
                  <h2>
                    {" "}
                    <p>
                      NEUTROGENA<sup>®&nbsp;</sup>VISIBLY CLEAR
                      <sup>®&nbsp;</sup>Spot proofing ™- Nawilżający krem do
                      twarzy
                    </p>
                  </h2>
                  <dl>
                    <dt>Opis:</dt>
                    <dd>
                      <p>
                        Redukuje wypryski już od pierwszego użycia i chroni
                        przed powstawaniem nowych. Lekki, łatwo wchłaniający się
                        krem zapewnia nawilżenie przez cały dzień, nie
                        zapychając przy tym porów. Formuła pomaga minimalizować
                        ślady po wypryskach dla zdrowego wyglądu cery. Skóra
                        pozostaje miękka, wyraźnie oczyszczona, a jej koloryt
                        wyrównany.
                      </p>
                      <p className="p1">
                        Zawiera unikalną technologię Clear Defend™, która:
                      </p>
                      <p className="p2">
                        <span className="s1">
                          - Dogłębnie oczyszcza pory, pomagając redukować
                          istniejące wypryski.
                        </span>
                      </p>
                      <p className="p2">
                        <span className="s1">
                          - Nie narusza bariery ochronnej skóry pozostawiając
                          większość naturalnie występujących w niej składników
                          odżywczych, aby skóra była bardziej odporna na
                          powstanie nowych wyprysków.
                        </span>
                      </p>
                      <p>
                        <span className="s1">&nbsp;</span>
                      </p>
                    </dd>
                  </dl>
                  <dl>
                    <dt>Stosowanie:</dt>
                    <dd>
                      Nakładać codziennie rano i wieczorem na dokładnie
                      oczyszczoną skórę twarzy. Stosować razem z innymi
                      produktami z linii VISIBLY CLEAR® Spot Proofing™. Unikać
                      kontaktu z oczami. W przypadku dostania się preparatu do
                      oczu należy przemyć je wodą.{" "}
                    </dd>
                  </dl>
                  <dl>
                    <dt>Opakowanie:</dt>
                    <dd>50ml</dd>
                  </dl>
                  <dl className="last">
                    <dt>Cena:</dt>
                    <dd>
                      24,99*{" "}
                      <p>
                        {" "}
                        <br />
                        *cena detaliczna sugerowana przez producenta
                      </p>
                    </dd>
                  </dl>
                </div>
                <div className="fb">
                  <div
                    style={{
                      width: "250px",
                      height: "auto",
                      float: "left"
                    }}
                  >
                    <iframe
                      src="//www.facebook.com/plugins/like.php?href=&send=false&layout=button_count&width=200&show_faces=false&font&colorscheme=light&action=like&height=21&locale=pl_PL"
                      scrolling="no"
                      frameBorder="0"
                      style={{
                        border: "none",
                        overflow: "hidden",
                        width: "200px",
                        height: "21px"
                      }}
                      allowTransparency="true"
                    />
                  </div>
                </div>
              </div>
              <div className="products">
                <div className="row">
                  <div className="rowtitle">Zobacz podobne produkty:</div>
                  <a
                    className="product group1_1 group2_1 group3_1"
                    href="/site/product/33/"
                  >
                    <img src="/site/assets/Uploads/62.png" alt="62" />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®&nbsp;</sup>Formuła
                        Norweska&nbsp;Ochronny sztyft do suchych i
                        spierzchniętych ust SPF 4
                      </p>
                    </span>
                    <span className="button">Szczegóły</span>
                    <span className="border" />
                  </a>
                  <a
                    className="product group1_1 group2_1 group3_1"
                    href="/site/product/34/"
                  >
                    <img src="/site/assets/Uploads/63.png" alt="63" />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup> Formuła Norweska&nbsp;Ochronny
                        sztyft do suchych i spierzchniętych ust SPF 20
                      </p>
                    </span>
                    <span className="button">Szczegóły</span>
                    <span className="border" />
                  </a>
                  <a
                    className="product group1_1 group2_1 group3_1"
                    href="/site/product/35/"
                  >
                    <img src="/site/assets/Uploads/64.png" alt="64" />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup> Formuła Norweska Intensywnie
                        regenerujący balsam do ust
                      </p>
                    </span>
                    <span className="button">Szczegóły</span>
                    <span className="border" />
                  </a>
                  <a
                    className="product group1_1 group2_1 group3_1"
                    href="/site/product/36/"
                  >
                    <img
                      src="/site/assets/Uploads/Packshoty-200-ml/sztyf-do-ust-z-malina-nordycka.png"
                      alt="sztyf do ust z malina nordycka"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup> Formuła Norweska Odżywczy sztyft
                        do ust z maliną nordycką
                      </p>
                    </span>
                    <span className="button">Szczegóły</span>
                    <span className="border" />
                  </a>
                  <a
                    className="product group1_1 group2_1 group3_1"
                    href="/site/product/15/"
                  >
                    <img
                      src="/site/assets/Uploads/NTG-3574660143843-Norwegian-Formula-Deep-Moisture-Comfort-Balm-200ml-00000-2.png"
                      alt="NTG 3574660143843 Norwegian Formula Deep Moisture Comfort Balm 200ml 00000 2"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup> Formuła Norweska Głęboko
                        nawilżający balsam do ciała i twarzy
                      </p>
                    </span>
                    <span className="button">Szczegóły</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bluebar">
          <div className="bar">
            <div className="wrapper">
              <a href="/site/o-neutrogenie/kim-jestesmy/">
                neutrogena<sup>®</sup> obecnie
              </a>
              <span>|</span>
              <a href="/site/o-neutrogenie/formula-norweska/">
                czym jest formuła norweska?
              </a>
              <span>|</span>
              <a href="/site/o-neutrogenie/historia/">poznaj naszą historię</a>
              <span>|</span>
              <a href="/site/produkty/">zobacz produkty neutrogena</a>
            </div>
          </div>
        </div>
        <footer>
          <div className="wrapper">
            <nav>
              <a href="/site/" className="logo2" />
              <a href="/site/nota-prawna/">Nota Prawna</a>
              <a href="/site/polityka-prywatnosci/">Polityka Prywatności</a>
               <a href="/site/cookies/">Polityka cookies</a>
              <a href="/site/kontakt/">Zachęcamy do kontaktu</a>
                           <span>© Johnson &amp; Johnson Poland Sp. z o.o. 2013 </span>
            </nav><a id="ot-sdk-btn" className="ot-sdk-show-settings">Ustawienia plików Cookie</a>
            <p>
              Strona zarządzana jest przez Johnson &amp; Johnson Poland Sp. z o.
              o., ul. Iłżecka 24, 02-135 Warszawa. Spółka zarejestrowana w
              Sądzie Rejonowym dla m. st. Warszawy, XIII Wydział Gospodarczy
              Krajowego Rejestru Sądowego, pod numerem KRS 00000322278, NIP
              113-00-20-467, wysokość kapitału zakładowego: 39.751.500 PLN.
              Johnson &amp; Johnson Poland Sp. z o. o. ponosi wyłączną
              odpowiedzialność za treści zawarte na niniejszej stronie.
              <br /> Strona jest przeznaczona dla użytkowników z Polski.
              Ostatnia aktualizacja: 25/10/2013
              <br />{" "}
            </p>{" "}
            <p>
              <span>
                Strona została zoptymalizowana pod kątem przeglądarek: Internet
                Explorer 7 i wyższych, Mozilla Firefox, Google Chrome, Opera,
                Safari
              </span>{" "}
              <span className="right">
                Zalecana rozdzielczość: 1024 x 768 pikseli
              </span>{" "}
            </p>
          </div>
        </footer>
        <noscript
          dangerouslySetInnerHTML={{
            __html:
              '<iframe src="//www.googletagmanager.com/ns.html?id=GTM-N7SHM8" height="0" width="0" style="display:none;visibility:hidden"></iframe>'
          }}
        />
        
        <noscript
          dangerouslySetInnerHTML={{
            __html:
              '<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=227575234312850&amp;ev=PageView&amp;noscript=1">'
          }}
        />
      </div>
    );
  }
}

export default Page;